/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_cart = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		/*
		Cart: Remove
		*/
		$(document).on('click','.js-cart-remove',function() { 

			var _this_product = $(this).data('id');

			specific_cart.remove(_this_product);

		});

		/*
		Cart: Counter
		*/
		$(document).on('click','.js-cart-counter',function() { 

			var _id_and_weight	= $(this).closest('.js-cart-item').data('id');
			var _plus_or_min 	= $(this).data('option');

			specific_cart.change_count(
				_id_and_weight,
				_plus_or_min
			);

		});

		/*
		Cart: Submit order
		*/
		$(document).on('click','.js-cart-order',function() { 
			specific_cart.order();
		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
			
	this.remove = function(id) {

		/*
		AJAX
		*/
		var _id = id.split("-");

		clearTimeout(_timeout_delay);

		_timeout_delay = setTimeout(function() {

			/*
			AJAX: Add to Session
			*/
			$.ajax({
				url: globalUrl + '/a/a/shop-cart-update',
				type: "POST",
				data: {
					product_id 		:_id[0],
					product_weight 	:_id[1],
					product_count 	:0
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				/*
				Hide
				*/
				$('.js-cart-' + id).fadeOut(100,function() {

					/*
					Remove
					*/
					$('.js-cart-' + id).remove();

					setTimeout(function() {
					
						/*
						Totals: Calculate
						*/
						specific_cart.totalsCalculate();

					},100);


				});

			})
			.always(function() {
				
			});

		});

	};

	this.change_count = function(
		id_and_weight,
		plus_or_minus
	) {

		var _counter_now = parseInt($('.js-cart-' + id_and_weight).find('input').val());

		if(plus_or_minus == 'plus') {
			_counter_now = _counter_now + 1;
		} else {
			_counter_now = _counter_now - 1;
		}

		/*
		This product is zero
		*/
		if(_counter_now <= 0) {

			$('.js-cart-' + id_and_weight).find('.js-cart-remove').click();

		} else {

			/*
			Set: New counter
			*/
			$('.js-cart-' + id_and_weight).find('input').val(_counter_now);

			var _id = id_and_weight.split("-");

			clearTimeout(_timeout_delay);

			_timeout_delay = setTimeout(function() {

				/*
				AJAX: Update to Session
				*/
				$.ajax({
					url: globalUrl + '/a/a/shop-cart-update',
					type: "POST",
					data: {
						product_id 		:_id[0],
						product_weight 	:_id[1],
						product_count 	:_counter_now
					},
					headers: { 
						'go-load':1 
					}
				})
				.done(function(data) {

					/*
					Init
					*/
					specific_shop.init();

				})
				.always(function() {
					
				});

			},500);

		}

		/*
		Totals: Calculate
		*/
		specific_cart.totalsCalculate();
		
	};

	/*
	Calculate totals realtime
	*/
	this.totalsCalculate = function() {

		var _total_price = 0;

		/*
		Check if there are products still
		*/
		var _products_counter = 0;
		$('.js-cart-item').map(function() {

			if($(this).is(":visible")) {
				_products_counter++;
			}

		});

		/*
		Cart: Nothing in there
		*/
		if(
			_products_counter == 0
		) {

			window.location.reload();

		/*
		Cart: Something in there
		*/
		} else {

			$('.js-cart-item-desktop').map(function() {

				var _price = $(this).data('price');
				var _count = $(this).find('input').val();

				/*
				Calculate
				*/
				_total_price = _total_price + (_price * _count);

			});

			/*
			Send costs add
			*/

			/*
			Sendcosts: Calculate
			*/
			var _sendcosts 		= 0;
			var _counter_total  = 0;
			var _weight_total 	= 0;

			$('.js-cart-item-desktop').map(function() {

				_weight 		= $(this).data('id').split('-');
				_counter 		= $(this).find('input').val();
				_counter_total 	= parseInt(_counter_total + _counter);
				_weight_total	= _weight_total + (_counter * parseInt(_weight[1]));

			});	

			/*
			Set: Counter
			*/
			$('.js-checkout-count').html(_counter_total);

			/*
			Calc
			*/

			$('.js-note-sendcosts-1').addClass('hidden');
			$('.js-note-sendcosts-2').addClass('hidden');

			if(_weight_total <= 999) {
				$('.js-sendcosts').data('price',4.95);
				$('.js-sendcosts-price').html('4,95');

				$('.js-note-sendcosts-1').removeClass('hidden');

			} else {
				
				if(_weight_total >= 2000) {
					$('.js-sendcosts').data('price',0.00);
					$('.js-sendcosts-price').html('0,00');
				} else {
					$('.js-sendcosts').data('price',3.95);
					$('.js-sendcosts-price').html('3,95');

					$('.js-note-sendcosts-2').removeClass('hidden');

				}
			}

			var _sendcosts_total	= parseFloat($('.js-sendcosts').data('price'));
			_total_price 			= _total_price + _sendcosts_total;

			/*
			Decimals
			*/
			_total_price 	= _total_price.toFixed(2);
			_total_price 	= _total_price.replaceAll('.',',');

			/*
			Set
			*/
			$('.js-cart-total').html(_total_price);

		}

	};

	/*
	Order
	*/
	this.order = function() {

		/*
		Check all fields
		*/
		var _name 						= $('.js-shop-order-name').val();
		var _email						= $('.js-shop-order-mail').val();
		var _address_receive			= $('.js-spikkl-receive-address').html();
		var _address_delivery			= $('.js-spikkl-delivery-address').html();

		var _address_receive_zipcode	= $('.js-shop-receive-zipcode').val();
		var _address_receive_number		= $('.js-shop-receive-number').val();

		var _address_delivery_zipcode	= $('.js-shop-delivery-zipcode').val();
		var _address_delivery_number	= $('.js-shop-delivery-number').val();

		var _privacy					= $('.js-checkbox-terms');

		if(_name == '') {
			popup.open(1,1,'error/name');
			return false;
		}

		var emailFilter = /^[^@]+@[^@.]+\.[^@]*\w\w$/;


		if(_email == '' || !emailFilter.test(_email)) {
			popup.open(1,1,'error/email');
			return false;
		}
		if(_address_receive == '' || _address_receive == 'Adres niet gevonden...') {
			popup.open(1,1,'error/address-receive');
			return false;
		}
		if(_address_delivery == 'Adres niet gevonden...') {
			popup.open(1,1,'error/address-delivery');
			return false;
		}
		if(_privacy.hasClass('active') == false) {
			popup.open(1,1,'error/privacy');
			return false;
		}

		/*
		AJAX: Post order and submit and make Mollie payment
		*/
		$.ajax({
			url: globalUrl + '/a/a/shop-cart-update-order',
			type: "POST",
			data: {
				order_name 				:_name,
				order_email 			:_email,

				order_receive 			:_address_receive,
				order_receive_zipcode	:_address_receive_zipcode,
				order_receive_number	:_address_receive_number,

				order_delivery 			:_address_delivery,
				order_delivery_zipcode	:_address_delivery_zipcode,
				order_delivery_number	:_address_delivery_number
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Redirect to Mollie for payment
			*/
			window.location.href = data;

			//console.log(data);

		})
		.always(function() {
			
		});

	};
	
}).apply(specific_cart); 