/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);

		/*
		Catch and active URL
		*/
		var _url_active_main = './';
		
		$('.js-nav-item').removeClass('active');
		
		if(url[2] !== '') { 
			_url_active_main = url[1] + '/' + url[2]; 
		} else {
			if(url[1] !== '') { 
				_url_active_main = url[1];
			}
		}

		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}

		/*
		Trigger: classes
		*/
		specific_overlaymenu.hide();

		/*
		Logo
		*/
		$('.js-header-logo').addClass('small');
		if(url[1] == '') {
			$('.js-header-logo').removeClass('small');
		}

		/*
		Confetti?
		*/
		if(url[1] == 'bedankt') {

			knalConfetti = setInterval(function() {
				jsConfetti.addConfetti({
				confettiRadius: 6,
				confettiColors: [
					'#FEF200', '#00FF01', '#FE700C', '#221DF6', '#FB2200', '#FF325D',
				],
				});
			},500);


		} else {

			clearInterval(knalConfetti);

		}

		/*
		Colors: Change on init
		*/
		var _background_array 		= ['green','orange','blue','red','rose'];
		var _random_color_1 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_2 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_3 		= _background_array[Math.floor(Math.random() * _background_array.length)];

		$('.js-footer-background').removeClass('backgroundcolor-green, backgroundcolor-blue, backgroundcolor-orange, backgroundcolor-rose, backgroundcolor-red');
		$('.js-footer-background').addClass('backgroundcolor-' + _random_color_1);

		$('.js-header-logo').find('.logo-payoff').removeClass('color-green color-blue color-orange color-rose color-red');
		$('.js-header-logo').find('.logo-payoff').addClass('color-' + _random_color_2);
		$('.js-header-logo').find('.logo-brand').removeClass('color-green color-blue color-orange color-rose color-red');
		$('.js-header-logo').find('.logo-brand').addClass('color-' + _random_color_3);

		var _random_color_4 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_5 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_6 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_7 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_8 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_9 		= _background_array[Math.floor(Math.random() * _background_array.length)];
		var _random_color_10 		= _background_array[Math.floor(Math.random() * _background_array.length)];

		$('.js-shop-color-1').removeClass('backgroundcolor-green, backgroundcolor-blue, backgroundcolor-orange, backgroundcolor-rose, backgroundcolor-red');
		$('.js-shop-color-1').addClass('backgroundcolor-' + _random_color_4);
		$('.js-shop-color-2').removeClass('color-green color-blue color-orange color-rose color-red');
		$('.js-shop-color-2').addClass('color-' + _random_color_5);
		$('.js-shop-color-3').removeClass('backgroundcolor-green, backgroundcolor-blue, backgroundcolor-orange, backgroundcolor-rose, backgroundcolor-red');
		$('.js-shop-color-3').addClass('backgroundcolor-' + _random_color_6);
		$('.js-shop-color-4').removeClass('color-green color-blue color-orange color-rose color-red');
		$('.js-shop-color-4').addClass('color-' + _random_color_7);
		$('.js-shop-color-5').removeClass('backgroundcolor-green, backgroundcolor-blue, backgroundcolor-orange, backgroundcolor-rose, backgroundcolor-red');
		$('.js-shop-color-5').addClass('backgroundcolor-' + _random_color_8);
		$('.js-shop-color-6').removeClass('color-green color-blue color-orange color-rose color-red');
		$('.js-shop-color-6').addClass('color-' + _random_color_9);
		
		/*
		Swiper
		*/
		var swiper = new Swiper('.swiper', {
		  
			loop: true,

			slidesPerView: "auto",
        	centeredSlides: true,
        	spaceBetween: 30,

        	autoplay: {
	          delay: 1500,
	          disableOnInteraction: false,
	        },

		});

		/*
		When in winkelmand
		*/
		if(url[1] == 'winkelmand') {

			var _products_counter = 0;
			$('.js-cart-item').map(function() {

				if($(this).is(":visible")) {
					_products_counter++;
				}

			});

			if(
				_products_counter >= 1
			) {

				specific_cart.totalsCalculate();

			}

		}
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
