/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_spikkl = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		/*
		Cart: Zipcode realtime Spikkl
		*/
		$(document).on('input','.js-shop-receive-zipcode,.js-shop-receive-number,.js-shop-delivery-zipcode,.js-shop-delivery-number',function() { 

			var _this_zipcode_category 	= $(this).data('category');
			
			var _this_zipcode 			= '';
			var _this_number 			= '';

			if(_this_zipcode_category == 'receive') {

				_this_zipcode 			= $('.js-shop-receive-zipcode').val();
				_this_number 			= $('.js-shop-receive-number').val();

			} else {

				_this_zipcode 			= $('.js-shop-delivery-zipcode').val();
				_this_number 			= $('.js-shop-delivery-number').val();

			}

			console.log(_this_zipcode);
			console.log(_this_number);

			/*
			Only when both are filled in
			*/
			if(
				_this_zipcode != '' && 
				_this_number != ''
			) {

				clearTimeout(_timeout_delay);

				_timeout_delay = setTimeout(function() {

					specific_spikkl.get(
						_this_zipcode,
						_this_number,
						_this_zipcode_category
					);

				},500);

			}

		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.get = function(
		_zipcode,
		_number,
		_category
	) {


		/*
		AJAX
		*/
		$.ajax({
			url: globalUrl + '/a/a/spikkl-get',
			type: "POST",
			data: {
				zipcode 	:_zipcode,
				number 		:_number,
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Address receive?
			*/
			if(_category == 'receive') {

				$('.js-spikkl-receive').removeClass('hidden');
				$('.js-spikkl-receive-address').html(data);

			/*
			Address delivery?
			*/
			} else {

				$('.js-spikkl-delivery').removeClass('hidden');
				$('.js-spikkl-delivery-address').html(data);

			}

		})
		.always(function() {
			
		});

	};
		
}).apply(specific_spikkl); 