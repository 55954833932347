/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_overlaymenu = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		$(document).on('click','.content',function() { 
			specific_overlaymenu.hide();
		});
		
		/*
		Demo: Button
		*/
		$(document).on('click','.js-overlaymenu-open',function() {
			specific_overlaymenu.show();
		});
		
		/*
		Demo: Button
		*/
		$(document).on('click','.js-overlaymenu-close',function() {
			specific_overlaymenu.hide();
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.show = function(
	) {

		$('.js-menu-overlay').stop().animate({
			right:0
		},500,'easein');

	};

	this.hide = function() {

		var _width = $('.js-menu-overlay').outerWidth();

		$('.js-menu-overlay').stop().animate({
			right:-_width
		},500,'easeout',function() {

			var _menu_background_array 	= ['orange','red','rose'];
			var _random_menu_color 		= _menu_background_array[Math.floor(Math.random() * _menu_background_array.length)];

			$('.js-menu-overlay').removeClass('backgroundcolor-green backgroundcolor-blue backgroundcolor-orange backgroundcolor-rose backgroundcolor-red');
			$('.js-menu-overlay').addClass('backgroundcolor-' + _random_menu_color);

		});
		
	};
		
}).apply(specific_overlaymenu); 