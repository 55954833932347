/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_shop = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		/*
		Init
		*/
		specific_shop.init();

		/*
		Check: if product is open
		*/
		if(
			url[1] == 'onze-koffies' && 
			url[2] !== ''
		) {
			specific_shop.open(url[2]);
		}

		/*
		Shop: Open
		*/
		$(document).on('click','.js-shop-open',function() { 
				
			var _product_id = $(this).data('id');

			specific_shop.open(_product_id);

		});

		/*
		Shop: Close
		*/
		$(document).on('click','.js-shop-close',function() { 
		
			specific_shop.close();

		});

		/*
		Shop: Add to cart
		*/
		$(document).on('click','.js-shop-addtocart',function() { 
		
			specific_shop.cartAdd();

		});

		/*
		Shop: Product++
		*/
		$(document).on('click','.js-shop-add',function() { 
			specific_shop.cartProductAdd();
		});

		/*
		Shop: Product--
		*/
		$(document).on('click','.js-shop-remove',function() { 
			specific_shop.cartProductRemove();
		});

		/*
		Weight
		*/
		$(document).on('click','.js-shop-weight',function() { 

			var _weight = $(this).data('weight');

			$('.js-shop-product-weight').val(_weight);
			$('.js-shop-weight').removeClass('active');

			$(this).addClass('active');

			/*
			Sub Totals: Calculate
			*/
			specific_shop.calculateSubTotals();

		});

		/*
		Checkbox: Switch active
		*/
		$(document).on('click','.js-checkbox',function() { 
			
			if($(this).hasClass('active')) {
				
				$(this).removeClass('active');

				/*
				Open
				*/
				if($(this).data('category') == 'same-address') {
					$('.js-deliveryaddress').removeClass('hidden');
				}

			} else {
				
				$(this).addClass('active');

				/*
				Close
				*/
				if($(this).data('category') == 'same-address') {
					$('.js-deliveryaddress').addClass('hidden');

					$('.js-spikkl-delivery-address').html('');
				}

			}


		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.init = function() {

		/*
		Init the cart
		*/
		$.ajax({
			url: globalUrl + '/a/a/shop-cart-init',
			type: "POST",
			data: {
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Set: Counter
			*/
			$('.js-checkout-count').html(data);

		})
		.always(function() {

		});

	};

	this.calculateSubTotals = function() {

		var _count_subtotals 	= 0;

		var _counter 			= $('.js-shop-product-count').val();
		var _price 				= $('.js-shop-product-price-500').val();
		var _weight 			= $('.js-shop-product-weight').val();

		if(_weight == 1000) {
			_price 				= $('.js-shop-product-price-1000').val();
		}

		/*
		Fix two decimals
		*/
		_count_subtotals 		= _count_subtotals;
		_count_subtotals 		= (_counter * _price).toFixed(2);
		_count_subtotals 		= _count_subtotals.replaceAll('.',',');

		/*
		Sub Totals
		*/
		$('.js-shop-total').html(_count_subtotals);
	};

	/*
	Open
	*/
	this.open = function(id) {

		/*
		AJAX
		*/
		$.ajax({
			url: globalUrl + '/a/a/shop-load-product',
			type: "POST",
			data: {
				id:id
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Set: Product
			*/
			$('.js-shop-loader').html(data);

			/*
			Shop: Open
			*/
			$('.shop').addClass('open');

			/*
			No scroll on body
			*/
			$('body').addClass('no-scroll');

			/*
			Sub Totals: Calculate
			*/
			specific_shop.calculateSubTotals();


		})
		.always(function() {
		});
	
	};

	/*
	Add or Remove
	*/
	this.cartProductAdd = function() {

		var _counter	= $('.js-shop-product-count').val();

		_counter++;

		$('.js-count').val(_counter);
		$('.js-shop-product-count').val(_counter);

		/*
		Sub Totals: Calculate
		*/
		specific_shop.calculateSubTotals();

	};

	this.cartProductRemove = function() {

		var _counter	= $('.js-shop-product-count').val();

		_counter--;

		if(_counter == 0) {
			_counter = 1;
		}

		$('.js-count').val(_counter);
		$('.js-shop-product-count').val(_counter);

		/*
		Sub Totals: Calculate
		*/
		specific_shop.calculateSubTotals();

	};

	/*
	Close
	*/
	this.close = function() {

		/*
		Emtpy: Product
		*/
		$('.js-shop-loader').html('');

		/*
		Shop: Open
		*/
		$('.shop').removeClass('open');

		/*
		No scroll on body
		*/
		$('body').removeClass('no-scroll');

	};

	/*
	Cart: Add
	*/
	this.cartAdd = function() {

		/*
		Get: Variables
		*/
		var _product_id 		= $('.js-shop-product-id').val();
		var _product_count 		= $('.js-shop-product-count').val();
		var _product_price 		= $('.js-shop-product-price-500').val();
		var _product_weight 	= $('.js-shop-product-weight').val();

		if(_product_weight == 1000) {
			_product_price 		= $('.js-shop-product-price-1000').val();
		}

		/*
		AJAX: Add to Session
		*/
		$.ajax({
			url: globalUrl + '/a/a/shop-cart-update',
			type: "POST",
			data: {
				product_id 		:_product_id,
				product_count 	:_product_count,
				product_price 	:_product_price,
				product_weight 	:_product_weight
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			/*
			Counter++
			*/
			var _checkout_count 	= parseInt($('.js-checkout-count').html());
			_checkout_count 		= _checkout_count + 1;

			$('.js-checkout-count').html(_checkout_count);

			/*
			Close: Overlay
			*/
			specific_shop.close();

			/*
			Click
			*/
			$('.js-checkout').trigger('click');

		})
		.always(function() {
			
		});

	};
		
}).apply(specific_shop); 